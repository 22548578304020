import 'bootstrap';
import "ol";

import "./scss/app.scss";


// import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js"
import "./mfFlexSlider";
import "./mfMaps";
import "./mfDropdownHover";
import "./mfSubNav";
import "./mfFormCheck";
