class MfFormCheck {
    constructor(searchId:string = "emailfirst",formValue:string = "example@test.de")
    {
        this.searchId = searchId;
        this.formValue = formValue;
        this.setSpamValue();
        this.setSectionValue();
        this.alertMessageToTop();
    }
    searchId: string;
    formValue: string;

    setSpamValue (){
        let items = document.getElementsByClassName(this.searchId);
        for(let i=0;i<items.length;i++) {
            let item = <HTMLInputElement>document.getElementById( items[i].id );
            if(item) {
                item.value = this.formValue;
            }
        }
    }
    setSectionValue (){
        let sectionName;
        let sectionNameTmp = document.getElementsByTagName('h1');
        if(sectionNameTmp && sectionNameTmp[0] ) {
            sectionName = sectionNameTmp[0].innerHTML;
        }
        let items = document.getElementsByClassName('immobilie');
        for(let i=0;i<items.length;i++) {
            let item = <HTMLInputElement>document.getElementById( items[i].id );
            if(item) {
                item.value = sectionName;
            }
        }
    }
    alertMessageToTop (){
        let triggerClick = function() {
            let subNav = document.getElementById('SubNav');
            if(subNav) {
                let links  = subNav.getElementsByTagName('a');
                if(links.length > 0) {
                    links[links.length-1].click();
                }
            }
        }
        let alerts = document.getElementsByClassName('alert');
        let formtype = document.getElementsByClassName('formOrder');
        if(alerts.length > 0 && formtype.length > 0 ) {
            setTimeout(triggerClick, 500);
        }
    }
};

$(function() {
    new MfFormCheck('emailfirst','a@giesicke.com');
});