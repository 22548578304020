/* Lightbox: */
/* https://www.w3schools.com/howto/howto_js_lightbox.asp */


import $ from "jquery";
import "flexslider";

class MfFlexSlider {
    constructor(flexClassName:string = "flexslider",
                animationClassName:string = "slides",
                itemWidth:number = 600
    )
    {
        this.flexClassName = flexClassName;
        this.animationClassName = animationClassName;
        this.itemWidth = itemWidth;
        this.createSlider();
    }
    flexClassName: string;
    animationClassName: any;
    itemWidth:number;

    createSlider (fId = 'undefined' ){
        let flexsliderId;
        if( fId === 'undefined' ) {
            let elem = document.getElementsByClassName( this.flexClassName );
            if(elem.length > 0) {
                flexsliderId = elem[0].getAttribute("id");
            }
        } else {
            flexsliderId = fId;
        }
        if( flexsliderId ) {
            $('#'+flexsliderId).flexslider({
                animation: this.animationClassName,
                itemWidth: this.itemWidth,
            });
        }
    };
    getItemlist ( fId = 'undefined') {
        let list = [];
        let flexsliderId;
        if( fId === 'undefined' ) {
            let elem = document.getElementsByClassName( this.flexClassName );
            if(elem.length > 0) {
                flexsliderId = elem[0].getAttribute("id");
            }
        } else {
            flexsliderId = fId;
        }
        if( flexsliderId ) {
            let flexContainer = document.getElementById(flexsliderId);
            let flexInnerContainer;
            if (flexContainer) {
                flexInnerContainer = flexContainer.getElementsByClassName(this.animationClassName);
            }
            if (flexInnerContainer && flexInnerContainer.length > 0) {
                list = flexInnerContainer[0].getElementsByTagName('li');
            }
        }
        return list;
    }
    getFlexContainer( fId = 'undefined' ) {
        let back;
        let flexsliderId;
        if( typeof fId === 'undefined' ) {
            let elem = document.getElementsByClassName( this.flexClassName );
            if(elem.length > 0) {
                flexsliderId = elem[0].getAttribute("id");
            }
        } else {
            flexsliderId = fId;
        }
        if( flexsliderId ) {
            back = document.getElementById( fId );
        }
        return back;
    }
}

class MfLightbox extends MfFlexSlider {
    constructor(flexClassName:string,
                animationClassName:string,
                itemWidth:number,
                modalId:string = 'MyModal'
    )
    {
        super(flexClassName, animationClassName, itemWidth );
        this.modalId = modalId;
        this.setCloseModal();
        this.activateFlexsliderItems();
        this.modalSlideIndex = 1;
    }
    modalId:string;
    modalSlideIndex:number;

    setCloseModal() {
        let that = this;
        let modalContainer = document.getElementById( this.modalId );
        var closeBtn;
        var prevBtn;
        var nextBtn;
        if( modalContainer ) {
            closeBtn = modalContainer.getElementsByClassName('close')[0];
            prevBtn = modalContainer.getElementsByClassName('prev')[0];
            nextBtn = modalContainer.getElementsByClassName('next')[0];
        }
        if ( closeBtn ) {
            closeBtn.addEventListener("click", function() {
                if( modalContainer ) {
                    modalContainer.style.display = "none";
                }
            });
        }
        if ( prevBtn ) {
            prevBtn.addEventListener("click", function() {
                that.showSlides(that.modalSlideIndex += -1);
            });
        }
        if ( nextBtn ) {
            nextBtn.addEventListener("click", function() {
                that.showSlides(that.modalSlideIndex += +1);
            });
        }
    }
    activateFlexsliderItems() {
        let itemList = this.getItemlist();
        let that = this;
        let modalContainer = document.getElementById( this.modalId );
        if(itemList) {
            for (let i = 0; i < itemList.length; i++) {
                if( itemList && itemList[i] ) {
                    let elem = itemList[i] as HTMLElement;
                    elem.addEventListener("click", function( val ){
                        if( modalContainer ) {
                            modalContainer.style.display = "block";
                            that.showSlides(that.modalSlideIndex = i);
                        }
                    });
                }
            }
        }
    }
    showSlides(n) {
        let slides = document.getElementsByClassName("modalItem");
        let modalContainer = document.getElementById( this.modalId );

        if (n > (slides.length-1) ) {
            this.modalSlideIndex = 0;
        }
        if (n < 0) {
            this.modalSlideIndex = (slides.length-1);
        }
        for (let i = 0; i < slides.length; i++) {
            if( slides && slides[i] ) {
                let elem = slides[i] as HTMLElement;
                elem.style.display = "none";
            }
        }
        let captionElem = modalContainer as HTMLElement;
        let tmpCaptionText = captionElem.getElementsByClassName("caption");
        let captionText;
        if(tmpCaptionText.length > 0) {
            captionText = tmpCaptionText[0];
        }

        if( slides && slides[this.modalSlideIndex]) {
            let elem = (slides[this.modalSlideIndex] as HTMLElement);
            elem.style.display = "block";
            if(captionText) {
                let elemImgs = elem.getElementsByTagName('img');
                if( elemImgs && elemImgs.length ) {
                    let elemImg = elemImgs[0];
                    if(elemImg) {
                        captionText.innerHTML = elemImg.title;
                    }
                }
            }
        }
    }

}

$(function() {
    var slider=new MfLightbox(
        "flexslider",
        "slides",
        600,
        'MfModal'
    );
});
