class MfSubNav {
    constructor(subNavId:string = "MfSubNav")
    {
        this.subNavId = subNavId;
        this.createSubNav();
    }
    subNavId: string;

    createSubNav (){
        let sectionsList = this.getSections();
        if(sectionsList.length > 0) {
            let sub = document.getElementById( this.subNavId );
            if(sub) {
                sub.innerHTML = '';
                for(let i=0; i<sectionsList.length; i++ ) {
                    let tarContainer = document.getElementById(sectionsList[i][0]);
                    let sectionId = "Sec"+sectionsList[i][0];
                    let tar = document.createElement('a');
                    tar.setAttribute('id', sectionId);

                    let item = document.createElement('a');
                    //let url = location.protocol+'//'+location.host+location.pathname;
                    item.setAttribute('href', "#"+sectionId );
                    item.text = sectionsList[i][1];
                    if(tarContainer && item.text.length > 0) {
                        tarContainer.insertBefore(tar,tarContainer.firstChild );
                        sub.appendChild(item);
                    }
                }
                this.activateScrollTo();
            }
        }
    };
    getSections () {
        let list:any = [];
        let sub = document.getElementById(this.subNavId);
        let elms = document.getElementsByTagName("section");
        if(sub && elms && elms.length > 0 ) {
            for (let i = 0; i < elms.length; i++) {
                let eleTitle = elms[i].getAttribute("data-sectiontitle");
                if(
                    elms[i].id && eleTitle !== null
                ) {
                    let eleId:string = elms[i].id;
                    let ele:string[] = [eleId, eleTitle.toString()];
                    list.push(ele);
                }
            }
        }
        return list;
    };
    activateScrollTo () {
        //window.console.debug('A');
        let subNavContainer = document.getElementById(this.subNavId);
        if( subNavContainer ) {
            let mainNav = document.getElementById('MainNav');
            let subNav = document.getElementsByClassName('pimcore_area_onepager-subnav');
            let tmpOffset = 0;
            if(subNav && subNav[0]) {
                tmpOffset = tmpOffset + subNav[0].clientHeight;
            }
            if(mainNav) {
                tmpOffset = tmpOffset + mainNav.clientHeight;
            }
            const offset:number = tmpOffset;

            let subNavLinks = subNavContainer.getElementsByTagName('a');
            if(subNavLinks && subNavLinks.length > 0) {
                for(let i=0; i<subNavLinks.length; i++) {
                    subNavLinks[i].addEventListener("click", function (e) {
                        let actualPos = document.body.scrollTop;
                        let tmpLink = this.getAttribute('href');
                        if(tmpLink) {
                            let link = tmpLink.substr(1);
                            if(link) {
                                let elmnt = document.getElementById(link);
                                if ( elmnt ) {
                                    window.scrollTo(0,0);
                                    let elementPosition = elmnt.getBoundingClientRect().top;
                                    let offsetPosition = elementPosition - offset;
                                    window.scrollTo({
                                        top: offsetPosition,
                                        behavior: "smooth"
                                    });

                                }
                            }
                        }
                        e.preventDefault();
                    });
                }
            }
        }
    }
}

$(function() {
    var slider=new MfSubNav(
        'SubNav'
    );
});
