
import $ from "jquery";


import * as L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.js";


$(function() {
    let geoLon:Number,geoLat:Number,geoZoom:Number,geoMarkerIcon:String,geoMarkerTitle:String;
    let mapData = document.getElementById('map');
    //let markerElement = document.getElementById('geoMarker');

    if(mapData) {
        let geoLonTmp = mapData.getAttribute('data-lon');
        if(geoLonTmp) {
            geoLon = parseFloat(geoLonTmp);
        } else {
            geoLon = 13.411238193511965;
        }

        let geoLatTmp = mapData.getAttribute('data-lat');
        if(geoLatTmp) {
            geoLat = parseFloat(geoLatTmp);
        } else {
            geoLat = 52.52152200406353;
        }

        let geoZoomTmp = mapData.getAttribute('data-zoom');
        if(geoZoomTmp) {
            geoZoom = parseInt(geoZoomTmp);
        } else {
            geoZoom = 14;
        }

        let geoMarkerIconTmp = mapData.getAttribute('data-markericon');
        if(geoMarkerIconTmp) {
            geoMarkerIcon = geoMarkerIconTmp;
        } else {
            geoMarkerIcon = '//cdn4.iconfinder.com/data/icons/pictype-free-vector-icons/16/location-alt-32.png';
        }

        let geoMarkerTitleTmp = mapData.getAttribute('data-markertitle');
        if(geoMarkerTitleTmp) {
            geoMarkerTitle = geoMarkerTitleTmp;
        } else {
            geoMarkerTitle = "Unnamed";
        }

        let markerIcon = L.icon({
            iconUrl: geoMarkerIcon,
            iconAnchor:   [10, 15], // point of the icon which will correspond to marker's location
            popupAnchor:  [7, -20] // point from which the popup should open relative to the iconAnchor
        });

        const map = L.map("map", {
            center: [geoLat,geoLon],
            zoom: geoZoom,
            gestureHandling: true
        });
        var OpenStreetMap_Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19
        }).addTo(map);

        var marker = L.marker([geoLat,geoLon],{icon: markerIcon})
            .bindPopup(geoMarkerTitle)
            .addTo(map);
    }
});
